import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/CataloguesAll.css";
import PurchaseOrdersSearchBar from "./CataloguesSearchBar";
import CatalogueNetworkUtils from "./NetworkUtils/CatalogueNetworkUtils";
import {
  Catalogue,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CataloguesSearchBar from "./CataloguesSearchBar";

function CataloguesStandard() {
  const history = useHistory();
  const location = useLocation();
  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  let pageNumber = 1;

  const [allCatalogues, setAllCatalogues] = useState<Catalogue[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [isLoading, setIsLoading] = useState(true);

  const getCataloguesPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await catalogueNetworkUtils.getStandardCataloguesPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          28
        );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllCatalogues((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching catalogues :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllCatalogues([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getCataloguesPaginated(query, 1, true);
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getCataloguesPaginated("", searchParams.current_page_num + 1, false);
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    getCataloguesPaginated("", 1, false);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const navigateToCataloguePage = (catalogue: Catalogue) => {
    // console.log("navigate to catalogue page triggered");
    // let url = `/catalogues/all/details/${catalogue.id}`;
    // const win = window.open(url, "_blank");
    // if (win) {
    //   win.focus(); // Focus on the new tab if it opened successfully
    // } else {
    //   console.error("Popup blocked. Please allow popups for this website.");
    // }
  };

  return (
    <div className="catalogues-container">
      <div className="catalogues-search-bar-container">
        <CataloguesSearchBar onSearch={handleSearch} />
      </div>
      {allCatalogues.length > 0 ? (
        <div className="catalogues-table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Catalogue Id</th>
                <th>Catalogue Name</th>
                <th>Catalogue Description</th>
                <th>SKU Count</th>
                <th>Catalogue Status</th>
                {/* <th>Min Price</th>
              <th>Max Price</th> */}
                <th>Date Created</th>
                <th>Last Updated</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {allCatalogues.map((catalogue, index) => {
                return (
                  <tr
                    className="catalogues-search-bar-row"
                    key={index}
                    onClick={() => navigateToCataloguePage(catalogue)}
                  >
                    <td className="po-id-link">
                      {catalogue.catalogue_status == "PUBLISHED" ? (
                        <a href={`/catalogues/all/details/${catalogue.id}`}>
                          {catalogue.id}
                        </a>
                      ) : (
                        <a href={`/catalogues/all/addItems/${catalogue.id}`}>
                          {catalogue.id}
                        </a>
                      )}
                    </td>
                    <td>{catalogue.catalogue_name}</td>
                    <td>{catalogue.catalogue_description}</td>
                    <td>{catalogue.sku_count}</td>
                    <td>{catalogue.catalogue_status}</td>
                    {/* <td>{catalogue.min_price}</td>
                  <td>{catalogue.max_price}</td> */}
                    <td>
                      {DateTimeUtils.formatDateTime(catalogue.created_at)}
                    </td>
                    <td>
                      {DateTimeUtils.formatDateTime(catalogue.updated_at)}
                    </td>
                    <td>{catalogue.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div className="load-more-catalogues-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-catalogues-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No catalogues were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default CataloguesStandard;
