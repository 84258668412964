import axios, { AxiosError } from "axios";
import { delay } from './delay';
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { fallBackFirstPage, fallBackSecondPage, fallBackThirdPage, fallbackImage, fallbackTopDesign } from './fallbackImage';


class CatalogueNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }
  async getCataloguesPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.getCataloguesPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getCataloguesPaginated");
        console.error("Error:", error);
        throw error;
        }
    }

    async getStandardCataloguesPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {
          const queryParams: Record<string, any> = {
          page: pageNumber, // New page value
          count: count, // New count value
          };
          try {
          let url = `${requestHeaders.rooturl}${requests.getStandardCataloguesPaginated}?search_keyword=${searchKeyword}`;
          if (queryParams) {
              const queryString = Object.keys(queryParams)
              .map((key) => `${key}=${queryParams[key]}`)
              .join("&");
              url += `&${queryString}`;
          }
          const response = await this.api.get(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from getStandardCataloguesPaginated");
          console.error("Error:", error);
          throw error;
          }
      }

      async getOneTimeCataloguesPaginated(
        searchKeyword: string,
        pageNumber: number,
        count: number
      ) {
            const queryParams: Record<string, any> = {
            page: pageNumber, // New page value
            count: count, // New count value
            };
            try {
            let url = `${requestHeaders.rooturl}${requests.getOnetimeCataloguesPaginated}?search_keyword=${searchKeyword}`;
            if (queryParams) {
                const queryString = Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join("&");
                url += `&${queryString}`;
            }
            const response = await this.api.get(url, { withCredentials: true });
            return response;
            } catch (error) {
            // Handle errors or return a default value as needed
            console.log("Printing error from getOnetimeCataloguesPaginated");
            console.error("Error:", error);
            throw error;
            }
        }

    async getCatalogueItems(catalogueId: string){
        try {
          let url = `${requestHeaders.rooturl}${requests.getCatalogueItemms}${catalogueId}`;
          const response = await this.api.get(url, {
            withCredentials: true,
          });
          return response;
    
        } catch (error) {
     
          console.log("Printing error from getCatalogueItems");
          console.error("Error:", error);
          throw error;
        }
    }

    async getCatalogueById(catalogueId: string){
        try {
          let url = `${requestHeaders.rooturl}${requests.getCatalogueById}${catalogueId}`;
          const response = await this.api.get(url, {
            withCredentials: true,
          });
          return response;
    
        } catch (error) {
     
          console.log("Printing error from getCatalogueById");
          console.error("Error:", error);
          throw error;
        }
    }

    async getCatalogueThemes(){
      try {
        let url = `${requestHeaders.rooturl}${requests.getCatalogueThemes}`;
        const response = await this.api.get(url, {
          withCredentials: true,
        });
        return response;
  
      } catch (error) {
   
        console.log("Printing error from getCatalogueThemes");
        console.error("Error:", error);
        throw error;
      }
    }

    async createNewCatalogue(newCatalogue: any) {
      try {
        let url = `${requestHeaders.rooturl}${requests.createNewCatalogue}`;
        const response = await this.api.post(url, newCatalogue, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from createNewCatalogue");
        console.error("Error:", error);
        throw error;
      }
    }

    async addSkusToCatalogue(catalogueItems : any, catalogueId: string){
      try {
        let url = `${requestHeaders.rooturl}${requests.addItemsToCatalogue}${catalogueId}`;
        const response = await this.api.put(url, catalogueItems, {
          withCredentials: true,
        });
        return response;
  
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from addSkusToCatalogue");
        console.error("Error:", error);
        throw error;
      }
    }

    async publishCatalogue(catalogueId: string){
      try {
        let url = `${requestHeaders.rooturl}${requests.publishCatalogue}${catalogueId}`;
        const response = await this.api.put(url, {}, {
          withCredentials: true,
        });
        return response;
  
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from publishCatalogue");
        console.error("Error:", error);
        throw error;
      }
    }    

    async downloadImage(image_name: string){
      try {
        let url = `${requestHeaders.rooturl}images/encoded/${image_name}`;
        const response = await this.api.get(url);
        console.log(response.data);
        return response.data;
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from downloadImage");
        console.error("Error:", error);
        throw error;
      }
    }    

    
    async downloadImageWithRetry(image_name: string, retries: number = 3, delayTime: number = 7000) : Promise<any> {
      // if (retries === 0) {
      //   return fallbackImage;
      // }

      let url = `${requestHeaders.rooturl}images/encoded/${image_name}`;
      try {
        const response = await this.api.get(url);
        // console.log(response.status)
        if (response.status === 429) {
          // If rate limited, wait and retry
          await delay(delayTime);
          return this.downloadImageWithRetry(image_name, retries - 1, delayTime * 2); // Exponential backoff
        }
        
        return response.data;
      } catch (error) {
        if (retries === 0) {
          return fallbackImage;
        }
        console.log(`Retrying after ${delayTime}ms`);
        await delay(delayTime);
        return this.downloadImageWithRetry(image_name, retries - 1, delayTime * 2); // Exponential backoff
      }
    }

    async downloadThemeImagesWithRetry(image_name : string, page_name : string, retries : number = 3, delayTime : number = 7000) : Promise<any>{
      // if (retries === 0) {
      //   if(page_name == "first_page") return fallBackFirstPage;
      //   else if(page_name == "second_page") return fallBackSecondPage;
      //   else if(page_name == "third_page") return fallBackThirdPage;
      //   else return fallbackTopDesign;
      // }

      let url = `${requestHeaders.rooturl}themes/encoded/${image_name}`;
      try {
        const response = await this.api.get(url);
        // console.log(response.status)
        if (response.status === 429) {
          // If rate limited, wait and retry
          await delay(delayTime);
          return this.downloadThemeImagesWithRetry(image_name, page_name, retries - 1, delayTime * 2); // Exponential backoff
        }
        
        return response.data;
      } catch (error) {
        if (retries === 0) {
          if(page_name == "first_page") return fallBackFirstPage;
          else if(page_name == "second_page") return fallBackSecondPage;
          else if(page_name == "third_page") return fallBackThirdPage;
          else return fallbackTopDesign;
        }
        console.log(`Retrying after ${delayTime}ms`);
        await delay(delayTime);
        return this.downloadThemeImagesWithRetry(image_name, page_name, retries - 1, delayTime * 2); // Exponential backoff
      }
    }

    async addItemToCatalogeInBulk(data: any[] | null, catalogueId:string) {
      try {
        let url = `${requestHeaders.rooturl}${requests.addItemToCatalogueInBulk}${catalogueId}`;
        const response = await this.api.post(url, data, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from addItemToCatalogeInBulk");
        console.error("Error:", error);
        throw error;
      }
    }
  
}

export default CatalogueNetworkUtils;
