import React, { ChangeEvent, useEffect, useState } from "react";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import { useHistory, useLocation } from "react-router-dom";
import {
  Catalogue,
  PurchaseOrder,
  Vendor,
} from "../../atomic_components/types";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Datetime from "react-datetime";
import DatePicker from "react-modern-calendar-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./CSS/CataloguesAdd.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { set } from "lodash";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CatalogueNetworkUtils from "./NetworkUtils/CatalogueNetworkUtils";

function CataloguesAdd() {
  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const [selectedCatalogue, setSelectedCatalogue] =
    useState("one-time-catalogue");

  const [newCatalogue, setNewCatalogue] = useState<Catalogue>({
    id: 0,
    catalogue_name: "",
    catalogue_description: "",
    catalogue_type: "ONETIME",
    catalogue_status: "PENDING",
    sku_count: 0,
    min_price: 0,
    max_price: 0,
    created_by: "",
    created_at: "",
    updated_at: "",
  });

  // Event handler to update the state when the input value changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewCatalogue({
      ...newCatalogue,
      [name]: value,
    });
  };

  const [providedSelectedValue, setProvidedSelectedValue] =
    useState<string>("");

  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  const handleDateTimeChange = (newDateTime: Date | null) => {
    setSelectedDateTime(newDateTime);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCatalogue(event.target.id); // Update the selected radio button when it's changed
  };

  const createCatalogueAndProceed = async () => {
    const body = {
      catalogue_name: newCatalogue.catalogue_name,
      catalogue_description: newCatalogue.catalogue_description,
      catalogue_type:
        selectedCatalogue == "one-time-catalogue" ? "ONETIME" : "STANDARD",
      created_by: userDetailsFromStore.user_details.userId,
    };
    try {
      const response = await catalogueNetworkUtils.createNewCatalogue(body);
      const createdCatalogue: Catalogue = response.data;
      history.push(`/catalogues/all/addItems/${createdCatalogue.id}`);
    } catch (error) {
      console.error("Error in fetching vendor listings by vendor id :", error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="form-container">
        <FloatingLabel
          controlId="floatingInput"
          label="Catalogue Name"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="catalogue_name"
            value={newCatalogue.catalogue_name}
            onChange={handleChange}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Catalogue Description"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="catalogue_description"
            value={newCatalogue.catalogue_description}
            onChange={handleChange}
          />
        </FloatingLabel>

        <Form>
          <div className="mb-3">
            <Form.Check
              type="radio"
              id="one-time-catalogue"
              name="radios"
              label="One Time Catalogue"
              checked={selectedCatalogue === "one-time-catalogue"}
              onChange={handleRadioChange}
            />

            <Form.Check
              type="radio"
              id="standard-catalogue"
              name="radios"
              label="Standard Catalogue"
              checked={selectedCatalogue === "standard-catalogue"}
              onChange={handleRadioChange}
            />
          </div>
        </Form>

        <div className="purchase-order-create-button-container mt-4">
          <Button
            className="purchase-order-create-button"
            variant="outline-primary"
            onClick={createCatalogueAndProceed}
          >
            Submit
          </Button>
        </div>
        {/* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
      /> */}
      </div>
    </div>
  );
}

export default CataloguesAdd;
