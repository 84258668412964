import React, { useState, useEffect } from "react";
import { Bin, Error, Listing } from "../../atomic_components/types";
import "./CSS/ListingBinGrid.css";
import _ from "lodash";
import ListingSaveInventoryDialog from "./ListingSaveInventoryDialog";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import {
  Listings__Action,
  TopListings__Action,
} from "../../state/action-creators";
import {
  ListingActionTypes,
  TopListingActionTypes,
} from "../../state/action-types";
import { useAppSelector } from "../../state/hooks";

interface BinGridProps {
  // bins: Bin[];
  // availableQuantity: number;
  listing: Listing;
}

const ListingBinGrid: React.FC<BinGridProps> = ({ listing }) => {
  const history = useHistory();
  const location = useLocation();
  // console.log(listing);
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const [inventoryEditMode, setInventoryEditMode] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [binList, setBinList] = useState<Bin[]>(_.cloneDeep(listing.bins));
  const [totalAvailableQuantity, setTotalAvailableQuantity] = useState<number>(
    listing.available_quantity
  );
  const [modifiedBinList, setModifiedBinList] = useState<Bin[]>(
    _.cloneDeep(listing.bins)
  );

  const [payloadBinList, setPayloadBinList] = useState<Bin[]>([]);

  useEffect(() => {
    setBinList(_.cloneDeep(listing.bins));
    setModifiedBinList(_.cloneDeep(listing.bins));
    setTotalAvailableQuantity(listing.available_quantity);
  }, [listing]);

  useEffect(() => {
    const sumOfAvailableQuantities: number = binList.reduce(
      (accumulator, bin) => accumulator + bin.available_quantity,
      0
    );
    setTotalAvailableQuantity(sumOfAvailableQuantities);
  }, [binList]);

  const handleAddQuantity = (index: number) => {
    const updatedBinList = [...modifiedBinList];
    updatedBinList[index].available_quantity++;
    setModifiedBinList(updatedBinList);
  };

  const handleSubtractQuantity = (index: number) => {
    const updatedBinList = [...modifiedBinList];
    if (updatedBinList[index].available_quantity > 0) {
      updatedBinList[index].available_quantity--;
      setModifiedBinList(updatedBinList);
    }
  };

  const handleEditQuantityClick = () => {
    setInventoryEditMode(true);
  };

  const handleCancelQuantityClick = () => {
    setInventoryEditMode(false);
    setModifiedBinList(_.cloneDeep(binList));
  };

  const handleSaveQuantityClick = () => {
    const copyList = _.cloneDeep(binList);
    const combinedBins = copyList
      .map((bin1, index) => {
        const bin2 = modifiedBinList[index];
        if (bin1.available_quantity === bin2.available_quantity) {
          return null; // Skip this bin
        }
        return {
          ...bin1, // properties from list1
          new_quantity: bin2.available_quantity, // new_quantity from list2
          available_quantity: bin1.available_quantity, // available_quantity from list1
        };
      })
      .filter((bin) => bin !== null);
    setSaveClicked(true);
    setPayloadBinList(combinedBins as Bin[]);
  };

  const handleDialogClose = () => {
    setSaveClicked(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await listingNetworkUtil.updateInventoryMulti(
        userDetailsFromStore.user_details.userId,
        payloadBinList
      );
      setBinList(_.cloneDeep(response.data));
      setModifiedBinList(_.cloneDeep(response.data));
      setSaveClicked(false);
      setInventoryEditMode(false);

      dispatch(
        Listings__Action(
          ListingActionTypes.UPDATE_LISTINGS,
          [],
          listing.sku_id,
          response.data
        )
      );

      dispatch(
        TopListings__Action(
          TopListingActionTypes.UPDATE_TOP_LISTINGS,
          [],
          listing.sku_id,
          response.data
        )
      );

      toast.success("Successfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.log("Error while updating inventory :", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        toast.error(
          "Concurrency issue, try submitting your changes after refreshing",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
      } else {
        toast.error("unexpected error!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setSaveClicked(false);
    }
  };

  const dispatch: AppDispatch = useDispatch();

  return (
    <div className="details-card">
      <div className="details-first-row small">
        <div>AVAILABLE QUANTITY : {totalAvailableQuantity}</div>
        {totalAvailableQuantity > 0 &&
          userDetailsFromStore.authorities.includes("AUTH_ADMIN") && (
            <div className="details-button-container">
              <button
                type="button"
                onClick={handleEditQuantityClick}
                disabled={inventoryEditMode}
              >
                Edit
              </button>
              <button
                type="button"
                onClick={handleCancelQuantityClick}
                disabled={!inventoryEditMode}
              >
                Cancel
              </button>
              {inventoryEditMode && (
                <button type="button" onClick={handleSaveQuantityClick}>
                  Save
                </button>
              )}
            </div>
          )}
      </div>
      {binList.length > 0 && (
        <div className="bin-grid">
          {modifiedBinList.map((bin, index) => (
            <div className="bin-item" key={bin.id}>
              <div className="bin-id">{bin.bin_id}</div>
              <div className="quantity">{bin.available_quantity}</div>

              {inventoryEditMode && (
                <div className="button-container mt-2">
                  <button
                    className="add-button mr-1"
                    onClick={() => handleAddQuantity(index)}
                  >
                    +
                  </button>
                  <button
                    className="subtract-button"
                    onClick={() => handleSubtractQuantity(index)}
                  >
                    -
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {binList.length == 0 && (
        <div className="associated-bins-not-available mt-2 mb-2">
          No bins were found.
        </div>
      )}

      <ListingSaveInventoryDialog
        open={saveClicked}
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
        payloadBinList={payloadBinList}
      />
      {/* {saveClicked && <div className="overlay"> HELLO</div>} */}
    </div>
  );
};

export default ListingBinGrid;
