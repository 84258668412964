import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { Bin, Listing, PacksAndCombos } from "../../../atomic_components/types";

class ListingNetworkUtil {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location: any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getListingsBySearchKeyword(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.ListingsBySearchKeyword}?search_keyword=${searchKeyword}`;
      if (queryParams) {
        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.post(url, {}, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from GetListingsBySearchKeyword");
      console.error("Error:", error);
      throw error;
    }
  }

  async getTopListings(pageNumber: number) {
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: 35, // New count value
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.topListings}`;
      if (queryParams) {
        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
        url += `?${queryString}`;
      }
      const response = await this.api.get(url, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getTopListings");
      console.error("Error:", error);
      throw error;
    }
  }

  async updateListingBySkuId(skuId: string, updatedListing: Listing) {
    try {
      let url = `${requestHeaders.rooturl}${requests.updateListingBySkuId}${skuId}`;
      const response = await this.api.put(url, updatedListing, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateListingBySkuId");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAnalyticsBySkuId(skuId: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.analyticsBySkuId}${skuId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAnalyticsBySkuId");
      console.error("Error:", error);
      throw error;
    }
  }

  async getListingBySkuId(skuId: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.listingsBySkuId}${skuId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAnalyticsBySkuId");
      console.error("Error:", error);
      throw error;
    }
  }

  async updateInventoryMulti(userId: string, bins: Bin[]) {
    try {
      let url = `${requestHeaders.rooturl}${requests.updateInventoryMulti}${userId}`;
      const response = await this.api.put(url, bins, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateInventoryMulti");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllSkuIds() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllSkuIds}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllSkuIds");
      console.error("Error:", error);
      throw error;
    }
  }

  async getCurrentListingsSnapshot(userId: String) {
    const queryParams: Record<string, any> = {
      created_by: userId, // New page value
    };

    try {
      let url = `${requestHeaders.rooturl}${requests.getCurrentListingsSnapshot}`;
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
      url += `?${queryString}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getCurrentListingsSnapshot");
      console.error("Error:", error);
      throw error;
    }
  }

  async getFilesHistory() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getFilesHistory}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getFilesHistory");
      console.error("Error:", error);
      throw error;
    }
  }

  async downloadFile(fileName: String) {
    try {
      let url = `${requestHeaders.rooturl}${requests.downloadFile}${fileName}`;
      const response = await this.api.get(url, {
        withCredentials: true,
        responseType: "blob",
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from downloadFile");
      console.error("Error:", error);
      throw error;
    }
  }

  async uploadUpdatedListings(file: File, userId: String) {
    try {
      const queryParams: Record<string, any> = {
        created_by: userId,
      };

      let url = `${requestHeaders.rooturl}${requests.uploadListingsUpdateFile}`;

      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");

      url += `?${queryString}`;

      const formData = new FormData();
      formData.append("file", file);

      const response = await this.api.post(url, formData, {
        withCredentials: true,
      });

      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from uploadUpdatedListings");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPacksAndComboDetails(sku_id: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getPacksAndComboDetails}${sku_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPacksAndComboDetails");
      console.error("Error:", error);
      throw error;
    }
  }
  async fetchIncomingInventoryBySkuId(sku_id: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.fetchIncomingInventoryBySkuId}${sku_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchIncomingInventoryBySkuId");
      console.error("Error:", error);
      throw error;
    }
  }

  async savePacksAndCombosMappingChanges(
    packsAndCombos: PacksAndCombos[],
    sku_id: string
  ) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getPacksAndComboDetails}${sku_id}`;
      const response = await this.api.put(url, packsAndCombos, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from savePacksAndCombosMappingChanges");
      console.error("Error:", error);
      throw error;
    }
  }

  async mapNewItemToPack(packsAndCombos: PacksAndCombos[], sku_id: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.mapNewItemToPack}${sku_id}`;
      const response = await this.api.post(url, packsAndCombos, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from mapNewItemToPack");
      console.error("Error:", error);
      throw error;
    }
  }
}

export default ListingNetworkUtil;
