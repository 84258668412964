import React, { useState, useEffect, useCallback } from "react";
import "./CSS/CatalogueItemsView.css";
import {
  CatalogueItem,
  ImageHashMapState,
} from "../../atomic_components/types";

import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ImageHashMap__Action } from "../../state/action-creators";
import { ImageHashMapActionTypes } from "../../state/action-types";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
  onImageIndexChange: (index: number) => void;
  catalogueItem: CatalogueItem;
}

const ListingImage: React.FC<ListingImageProps> = ({
  images,
  onImageIndexChange,
  catalogueItem,
}) => {
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const hashMap: ImageHashMapState = useAppSelector(
    (state) => state.image_hashmap_store
  );
  const dispatch: AppDispatch = useDispatch();

  // useEffect(() => {
  //   let newIndex = 0;
  //   for (let i = 0; i < images.length; i++) {
  //     if (images[i] && images[i].trim() !== "") {
  //       newIndex = i;
  //       break;
  //     }
  //   }

  //   onImageIndexChange(newIndex);
  //   setCurrentImageIndex(newIndex);
  // }, [images]);

  // useEffect(() => {
  //   let nextIndex = hashMap.imageHashmap[catalogueItem.listing.sku_id];
  //   console.log(nextIndex);
  //   if (!nextIndex) {
  //     dispatch(
  //       ImageHashMap__Action(ImageHashMapActionTypes.ADD_TO_MAP, {
  //         key: catalogueItem.listing.sku_id,
  //         value: 0,
  //       })
  //     );
  //   }
  // }, [images]);

  const handleImageError = () => {
    let nextIndex = hashMap.imageHashmap[catalogueItem.listing.sku_id];
    if (nextIndex) nextIndex = nextIndex + 1;
    else nextIndex = 1;

    while (
      nextIndex < images.length &&
      (images[nextIndex] == null || images[nextIndex].trim() === "")
    ) {
      nextIndex++;
    }

    dispatch(
      ImageHashMap__Action(ImageHashMapActionTypes.ADD_TO_MAP, {
        key: catalogueItem.listing.sku_id,
        value: nextIndex,
        catalogueItems: [],
      })
    );

    // setCurrentImageIndex((prevIndex) => {
    //   let nextIndex = prevIndex + 1;
    //   // Check if the next index is out of bounds or if the next image is null or empty
    //   while (
    //     nextIndex < images.length &&
    //     (images[nextIndex] == null || images[nextIndex].trim() === "")
    //   ) {
    //     nextIndex++;
    //   }
    //   // console.log("Printing nextIndex from imageerror function ", nextIndex);
    //   onImageIndexChange(nextIndex);
    //   return nextIndex;
    // });
  };

  return (
    <div className="listings-img-container">
      {hashMap.imageHashmap[catalogueItem.listing.sku_id] > 4 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
          onError={handleImageError}
        />
      ) : (
        <img
          src={images[hashMap.imageHashmap[catalogueItem.listing.sku_id]]}
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  catalogueItem: CatalogueItem;
  handleCardClick: (listing: CatalogueItem) => void;
  handleImageIndexChange: (listing: CatalogueItem, index: number) => void;
  selectedImageKey: string;
}> = ({
  catalogueItem,
  handleCardClick,
  handleImageIndexChange,
  selectedImageKey,
}) => {
  // const onImageIndexChange = (index: number) => {
  //   // setUsedImageIndex(index);
  //   // console.log("Printing from index change ", catalogueItem, index);
  //   handleImageIndexChange(catalogueItem, index);
  // };

  const onImageIndexChange = useCallback(
    (index: number) => {
      handleImageIndexChange(catalogueItem, index);
    },
    [catalogueItem, handleImageIndexChange]
  );

  return (
    <div
      className="listings-view-card vendor-listing-card"
      onClick={() => handleCardClick(catalogueItem)}
    >
      <div className="listings-img-container">
        {selectedImageKey == "img_wdim" && (
          <img src={catalogueItem.listing.img_wdim} alt="Product Image" />
        )}
        {selectedImageKey == "img_hd" && (
          <img src={catalogueItem.listing.img_hd} alt="Product Image" />
        )}
        {selectedImageKey == "img_link1" && (
          <img src={catalogueItem.listing.img_link1} alt="Product Image" />
        )}
        {selectedImageKey == "img_link2" && (
          <img src={catalogueItem.listing.img_link2} alt="Product Image" />
        )}
        {selectedImageKey == "img_white" && (
          <img src={catalogueItem.listing.img_white} alt="Product Image" />
        )}
        {selectedImageKey == "any_image" && (
          <ListingImage
            images={[
              catalogueItem.listing.img_wdim,
              catalogueItem.listing.img_hd,
              catalogueItem.listing.img_white,
              catalogueItem.listing.img_link1,
              catalogueItem.listing.img_link2,
            ]}
            onImageIndexChange={onImageIndexChange}
            catalogueItem={catalogueItem}
          />
        )}
      </div>

      <div className="listings-view-card-title">
        <h3
          className={`sku-id-title ${
            catalogueItem.listing.sku_type == "MULTI"
              ? ""
              : catalogueItem.listing.available_quantity === 0
              ? "not-available"
              : "available"
          }`}
        >
          {catalogueItem.listing.sku_id}
        </h3>
      </div>
      <p>OPS Tag: {catalogueItem.listing.ops_tag}</p>

      {catalogueItem.listing.sku_type == "MULTI" ? (
        <p className="catalogue-listings-moq">MULTI</p>
      ) : (
        <p className="catalogue-listings-moq">
          Available Quantity: {catalogueItem.listing.available_quantity}
        </p>
      )}
      {/* <p className="catalogue-listings-moq">M.O.Q: {catalogueItem.moq}</p> */}
      <p className="catalogue-listings-moq">
        Original Transfer Price: {catalogueItem.listing.bulk_price}
      </p>
      <p className="catalogue-listings-moq">
        New Transfer Price: {catalogueItem.new_transfer_price}
      </p>

      {/* <div className="cost-price-container">
      <p>Cost Price : </p>
      <input
        type="text"
        name="vendor_cost_price"
        value={catalogueItem.cost_price}
      />
    </div> */}

      <p>
        {`L.W.H:   ${catalogueItem.listing.length}*${catalogueItem.listing.width}*${catalogueItem.listing.height}`}
      </p>
    </div>
  );
};

type CatalogViewProps = {
  catalogueItems: CatalogueItem[];
  onCardClick: (selectedListing: CatalogueItem) => void;
  selectedImageKey: string;
  handleImageIndexChangeMain: (listing: CatalogueItem, index: number) => void;
};

export default function CatalogueItemsView({
  catalogueItems,
  onCardClick,
  selectedImageKey,
  handleImageIndexChangeMain,
}: CatalogViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const handleCardClick = async (listing: CatalogueItem) => {
    onCardClick(listing);
  };

  const handleImageIndexChange = async (
    listing: CatalogueItem,
    index: number
  ) => {
    handleImageIndexChangeMain(listing, index);
  };

  return (
    <div className="vendor-listings-container-main">
      {catalogueItems && catalogueItems.length > 0 ? (
        <div className="catalogue-listings-card-container">
          {catalogueItems.map((catalogueItem, index) => (
            <ListingCard
              key={index}
              catalogueItem={catalogueItem}
              handleCardClick={handleCardClick}
              selectedImageKey={selectedImageKey}
              handleImageIndexChange={handleImageIndexChange}
            />
          ))}
        </div>
      ) : (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
