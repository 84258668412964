import { Bin, ImageHashMapState, Listing } from "../../atomic_components/types";
import { ImageHashMapActionTypes } from "../action-types";
import { ImageHashMapAction } from "../actions-interface";



const initialState: ImageHashMapState = {
    imageHashmap: {}
};

const imageHashMapReducer = (
    state: ImageHashMapState = initialState,
    action: ImageHashMapAction
) => {
  switch (action.type) {
    case ImageHashMapActionTypes.ADD_TO_MAP:
        console.log(state);
        return {
            ...state,
            imageHashmap: {
              ...state.imageHashmap,
              [action.payload.key]: action.payload.value
            }
          };
    case ImageHashMapActionTypes.RESET_MAP:
        return {
            ...state,
            imageHashmap: {}
          };
    case ImageHashMapActionTypes.INIT_MAP:
      const newState : ImageHashMapState = {
        imageHashmap: {}
      };
      action.payload.catalogueItems.forEach((catalogueItem) => {
        if(catalogueItem.listing.img_wdim && catalogueItem.listing.img_wdim.trim() !== "") newState.imageHashmap[catalogueItem.listing.sku_id] = 0;
        else if(catalogueItem.listing.img_hd && catalogueItem.listing.img_hd.trim() !== "") newState.imageHashmap[catalogueItem.listing.sku_id] = 1;
        else if(catalogueItem.listing.img_white && catalogueItem.listing.img_white.trim() !== "") newState.imageHashmap[catalogueItem.listing.sku_id] = 2;
        else if(catalogueItem.listing.img_link1 && catalogueItem.listing.img_link1.trim() !== "") newState.imageHashmap[catalogueItem.listing.sku_id] = 3;
        else if(catalogueItem.listing.img_link2 && catalogueItem.listing.img_link2.trim() !== "") newState.imageHashmap[catalogueItem.listing.sku_id] = 4;
        else newState.imageHashmap[catalogueItem.listing.sku_id] = 5;
      })
      console.log("Printing new state : ", newState);
      return {
          ...state,
          imageHashmap: newState.imageHashmap
        };
    default:
      return state;
  }
};
export default imageHashMapReducer;
