import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import "./CSS/ListingsSearchBar.css";
import { useAppSelector } from "../../state/hooks";
import { SearchParams } from "../../atomic_components/types";

type Props = {
  onSearch: (query: string) => void;
};

function ListingsSearchBar({ onSearch }: Props) {
  // const searchTextFromStore = useAppSelector((state) => state.search_text);
  const searchParams: SearchParams = useAppSelector(
    (state) => state.search_params
  );
  // const [searchQuery, setSearchQuery] = useState(searchTextFromStore);
  const [searchQuery, setSearchQuery] = useState(searchParams.search_string);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log(searchQuery);
      onSearch(searchQuery);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
}

export default ListingsSearchBar;
