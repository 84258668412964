// to prevent hardcoding
// Variables
export enum ActionType {
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
    BANKRUPT = "bankrupt",
    USER_ID = "user_id",
    ACTIVE_TAB = "active_tab",
    WINDOW_SMALL = "window_small",
    SIDE_BAR_OPTION = "side_bar_option",
    PO_ID = "PO_ID",
    PO = "PO"
}

export enum ActiveTabActionTypes{
    SET_ACTIVE_TAB = "set_active_tab"
}

export enum SearchTextActionTypes{
    SET_SEARCH_TEXT = "set_search_text",    
    SET_SEARCH_PARAMS = "set_search_params"
}



export enum ListingActionTypes{
    ADD_LISTINGS = "add_listings",
    RESET_LISTINGS = "reset_listings",
    REPLACE_LISTINGS = "replace_listings",
    UPDATE_LISTINGS = "update_listings",
    UPDATE_LISTING_DETAILS = "update_listing_details"
}

export enum TopListingActionTypes{
    ADD_TOP_LISTINGS = "add_top_listings",
    RESET_TOP_LISTINGS = "reset_top_listings",
    UPDATE_TOP_LISTINGS = "update_top_listings",
    UPDATE_TOP_LISTING_DETAILS = "update_top_listing_details"
}

export enum ImageHashMapActionTypes{
    ADD_TO_MAP = "add_to_map",
    INIT_MAP = "init_map",
    RESET_MAP = "reset_map",
}

export enum UserDetailsActionTypes{
    ADD_USER_DETAILS = "add_user_details"
}