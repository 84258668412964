import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import {
  Catalogue,
  CatalogueItem,
  CatalogueTheme,
  ImageHashMap,
  ImageHashMapState,
  ThemeControls,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { requestHeaders } from "../../NetworkUtils/Requests";
import { useAppSelector } from "../../state/hooks";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  itemPage: {
    position: "relative",
    width: "100%",
    height: "100%",
    // backgroundImage: 'url("/catalogue/bg1.png")',
    // backgroundSize: "cover", // Adjust this property based on your needs
    // backgroundPosition: "center", // Adjust this property based on your needs
    // opacity: 0.5,
  },
  // container: {
  //   position: "relative",
  // },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  imageContainer: {
    width: "35%", // Adjust the width as needed
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    borderRight: "1px solid black",
  },
  detailsContainer: {
    width: "65%", // Adjust the width as needed
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  productImage: {
    width: "90%", // Adjust the image width as needed
    height: 180,
  },
  poHeaderText: {
    fontSize: 16, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
  },
  catalogueItemText: {
    fontSize: 12, // Adjust the font size for item details
    marginBottom: 3,
    marginRight: 5,
  },
  requiredQuantity: {
    fontSize: 14, // Adjust the font size for item details
    marginBottom: 3,
  },
  itemsContainer: {
    // position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
  },
  itemContainer: {
    flexDirection: "row",
    margin: 5,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
    border: "1px solid black",
  },
  frontPageContainer: {
    width: "100%",
    height: "100%",
  },
  frontPageImage: {
    width: "100%", // Set the width to fill the page
    height: "100%", // Set the height to fill the page
    objectFit: "cover",
  },
  pageHeaderImage: {
    width: "100%",
    height: "10%",
  },
  addressContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  addressColumn: {
    width: "48%", // Adjust the width as needed
    border: "1px solid #ccc",
    borderRadius: 5,
    marginBottom: 10,
  },
  poIdColumn: {
    width: "48%", // Adjust the width as needed
    marginBottom: 10,
    fontSize: 12,
  },
  addressColumnDetails: {
    padding: 10,
    fontSize: 12,
  },
  addressTitle: {
    fontWeight: "ultrabold",
    fontSize: 12,
    marginBottom: 5,
    backgroundColor: "#EC1B30",
    color: "#FFFFFF",
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  addressText: {
    fontSize: 10,
  },
  labelAndDateContainer: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: 12,
  },
  watermarkImage: {
    position: "absolute",
    width: "100%", // Set the width to fill the page
    height: "100%", // Set the height to fill the page
    objectFit: "cover",
    opacity: 0.5,
    top: 0,
    left: 0,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1, // Ensure the watermark is positioned above other elements
  },
  overlayContent: {
    position: "absolute",
    width: "100%", // Set the width to fill the page
    height: "100%",
  },
});

const randomFrontPageImageFunction = () => {
  const startRange = 1;
  const endRange = 4;
  const index =
    Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
  return `/catalogue/catalogue_front_page_${index}.PNG`;
};

const randomSecondPageImageFunction = () => {
  const startRange = 1;
  const endRange = 4;
  const index =
    Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
  return `/catalogue/catalogue_second_page_${index}.PNG`;
};

const randomThirdPageImageFunction = () => {
  const startRange = 1;
  const endRange = 4;
  const index =
    Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
  return `/catalogue/catalogue_third_page_${index}.PNG`;
};

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
  sku_id: string;
  hashMap: ImageHashMapState;
}

const ListingImagePDF: React.FC<ListingImageProps> = ({
  images,
  sku_id,
  hashMap,
}) => {
  // console.log(imageHashMap);
  const img_index = hashMap.imageHashmap[sku_id];
  console.log(sku_id, img_index);
  if (img_index > 4) {
    return (
      <Image
        src={{
          uri: `${requestHeaders.rooturl}images/not-available.jpg`,
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
        style={styles.productImage}
      />
    );
  }
  // } else {
  return (
    <Image
      src={{
        uri: `${requestHeaders.rooturl}images/${images[img_index].substring(
          images[img_index].lastIndexOf("/") + 1
        )}`,
        method: "GET",
        headers: { "Cache-Control": "no-cache" },
        body: "",
      }}
      style={styles.productImage}
    />
  );
  // }
};

const CataloguePDF_v4: React.FC<{
  chunkedCatalogueItems: CatalogueItem[][];
  catalogue: Catalogue | null;
  isAddWaterMarkChecked: Boolean;
  selectedCatalogueTheme: CatalogueTheme;
  selectedImageKey: string;
  themeControls: ThemeControls;
  hashMap: ImageHashMapState;
  imageSources: string[];
  themeSources: string[];
}> = ({
  chunkedCatalogueItems,
  catalogue,
  isAddWaterMarkChecked,
  selectedCatalogueTheme,
  selectedImageKey,
  themeControls,
  hashMap,
  imageSources,
  themeSources,
}) => {
  return (
    <Document>
      {themeSources.map((pageTheme, themeIndex) => {
        if (themeIndex <= 2) {
          return (
            <Page size="A4" style={styles.page}>
              <Image
                src={`data:image/png;base64,${pageTheme}`}
                style={styles.frontPageImage}
              />
            </Page>
          );
        }
        return null;
      })}

      {chunkedCatalogueItems.map((singleChunk, mainChunkindex) => (
        <Page size="A4" style={styles.itemPage}>
          {/* <View style={styles.overlayContent}>
            {isAddWaterMarkChecked && (
              <Image
                src="/catalogue/catalogue_watermark.png"
                style={styles.watermarkImage}
              />
            )}
          </View> */}
          <Image
            src={`data:image/png;base64,${themeSources[3]}`}
            style={styles.pageHeaderImage}
          />

          <View style={styles.itemsContainer}>
            {singleChunk.map((catalogueItem, singleChunkindex) => (
              <View
                key={mainChunkindex * 3 + singleChunkindex}
                style={styles.itemContainer}
              >
                <View style={styles.imageContainer}>
                  {imageSources.length > 0 && (
                    <Image
                      src={`data:image/jpeg;base64,${
                        imageSources[mainChunkindex * 3 + singleChunkindex]
                      }`}
                      style={styles.productImage}
                    />
                  )}
                  {/* {selectedImageKey == "any_image" && (
                    <ListingImagePDF
                      images={[
                        catalogueItem.listing.img_wdim,
                        catalogueItem.listing.img_hd,
                        catalogueItem.listing.img_white,
                        catalogueItem.listing.img_link1,
                        catalogueItem.listing.img_link2,
                      ]}
                      sku_id={catalogueItem.listing.sku_id}
                      hashMap={hashMap}
                    />
                  )} */}
                </View>

                <View style={styles.detailsContainer}>
                  {themeControls.description && (
                    <Text
                      style={styles.catalogueItemText}
                    >{`Description: ${catalogueItem.listing.description}`}</Text>
                  )}
                  <Text
                    style={styles.catalogueItemText}
                  >{`SKU ID: ${catalogueItem.listing.sku_id}`}</Text>

                  {themeControls.transferPrice && (
                    <Text
                      style={styles.catalogueItemText}
                    >{`Unit Price(in Rs): ${catalogueItem.new_transfer_price}`}</Text>
                  )}
                  {themeControls.lwh && (
                    <Text style={styles.catalogueItemText}>
                      {`L.W.H. : ${catalogueItem.listing.dimension}`}
                    </Text>
                  )}
                </View>
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};
export default CataloguePDF_v4;
