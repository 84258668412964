import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Bin } from "../../atomic_components/types";

interface SaveDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  payloadBinList: Bin[];
}

const ListingSaveInventoryDialog: React.FC<SaveDialogProps> = ({
  open,
  onClose,
  onSubmit,
  payloadBinList,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Are you sure you want to submit inventory changes?
      </DialogTitle>
      <DialogContent>
        {payloadBinList.map((bin, index) => {
          if (bin.available_quantity > bin.new_quantity) {
            return (
              <div key={index}>
                {bin.bin_id} <b>REMOVE</b> (
                {bin.available_quantity - bin.new_quantity})
              </div>
            );
          } else {
            return (
              <div key={index}>
                {bin.bin_id} <b>ADD</b> (
                {bin.new_quantity - bin.available_quantity})
              </div>
            );
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListingSaveInventoryDialog;
