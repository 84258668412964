import React, { useEffect, useState } from "react";
import "./CSS/ListingsHome.css";
import ListingsView from "./ListingsView";
import { Listing } from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import { TopListings__Action } from "../../state/action-creators";
import { TopListingActionTypes } from "../../state/action-types";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";

function ListingsTop() {
  const topListingsFromStore = useAppSelector(
    (state) => state.top_listings_store
  );
  // console.log("from top listings store", topListingsFromStore);
  const [listings, setListings] = useState<Listing[]>(topListingsFromStore);

  useEffect(() => {
    dispatch(
      TopListings__Action(TopListingActionTypes.RESET_TOP_LISTINGS, [], "", [])
    );
    search(1);
  }, []);

  useEffect(() => {
    setListings(topListingsFromStore);
  }, [topListingsFromStore]);

  const dispatch: AppDispatch = useDispatch();
  const [itemCount, setItemCount] = useState(0);
  const [expectedItemCount, setExpectedItemCount] = useState(0);
  const [currentpageNum, setcurrentpageNum] = useState(1);

  const history = useHistory();
  const location = useLocation();
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const search = async (pageNumber?: number) => {
    try {
      const response = await listingNetworkUtil.getTopListings(
        pageNumber ? pageNumber : currentpageNum
      );
      setItemCount(itemCount + response.data["curr_page_count"]);
      setExpectedItemCount(response.data["total"]);
      if (pageNumber) setcurrentpageNum(pageNumber + 1);
      else setcurrentpageNum(currentpageNum + 1);
      const newListings: Array<Listing> = response.data["content"];
      // console.log(newListings);
      setListings((prevList) => [...prevList, ...newListings]);
      dispatch(
        TopListings__Action(
          TopListingActionTypes.ADD_TOP_LISTINGS,
          newListings,
          "",
          []
        )
      );
    } catch (error) {
      // if(axios.isAxiosError(error)){
      //   if (error.response && error.response.status === 401) {
      //     console.log('Unauthorized request in catch block. Redirecting to login page.');
      //     history.push('/login');
      //   }
      // }
      console.log("Printing error from ListingsHome");
      console.error("Error:", error);
    }
  };

  return (
    <div className="PageContainer">
      <div className="all-listings-container mt-4">
        <ListingsView listings={listings} />
        {itemCount > 0 && itemCount < expectedItemCount && (
          <div>
            <Button
              variant="outline-primary"
              className="next-button"
              onClick={() => search()}
            >
              Load More..
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListingsTop;
