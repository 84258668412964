import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./CSS/ListingBulkOperations.css";
import { useHistory, useLocation } from "react-router-dom";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { FilesStatus } from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { toast } from "react-toastify";

const ListingsBulkOperations: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [show, setShow] = useState(true);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [filesHistory, setFilesHistory] = useState<FilesStatus[]>([]);
  const history = useHistory();
  const location = useLocation();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    // Perform upload logic with the selected file
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const response = await listingNetworkUtil.uploadUpdatedListings(
        file,
        userDetailsFromStore.user_details.userId
      );
      toast.success("Upload successful", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      getFilesHistory();
      const fileInput = document.getElementById(
        "fileInput"
      ) as HTMLInputElement | null;
      if (fileInput) {
        fileInput.value = ""; // Clear the value
      }
      setFile(null);

      console.log(response);
    } catch (error) {
      console.log("Printing error from handleUpload updated inventory.");
      console.error("Error:", error);
    }
  };

  const getFilesHistory = async () => {
    try {
      const response = await listingNetworkUtil.getFilesHistory();
      setFilesHistory(response.data);
      console.log(response);
    } catch (error) {
      console.log(
        "Printing error from ListingBulkOperations download inventory snapshot"
      );
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getFilesHistory();
  }, []);

  const handleDownload = async () => {
    try {
      const response = await listingNetworkUtil.getCurrentListingsSnapshot(
        userDetailsFromStore.user_details.userId
      );
      setDownloadButtonClicked(true);
      getFilesHistory();
      console.log(response);
    } catch (error) {
      console.log(
        "Printing error from ListingBulkOperations download inventory snapshot"
      );
      console.error("Error:", error);
    }
  };

  const downloadLoadedFile = async (fileName: string) => {
    try {
      const response = await listingNetworkUtil.downloadFile(fileName);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName; // Specify the desired file name
      document.body.appendChild(link);

      // Trigger a click on the link to initiate download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.log(
        "Printing error from ListingBulkOperations download inventory snapshot"
      );
      console.error("Error:", error);
    }
  };

  return (
    <div className="bulk-operations-container pt-4 pl-4 pr-4 pb-4">
      {/* Upload Section */}
      {show && (
        <Alert
          variant="secondary"
          onClose={() => setShow(false)}
          dismissible
          style={{
            fontSize: "14px",
          }}
        >
          Please be advised that the process of retrieving and updating the
          files may take some time, as it involves thorough and comprehensive
          procedures to ensure accuracy and completeness.
        </Alert>
      )}

      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col>
            <div className="upload-snapshot-container">
              <h6>Upload Updated Listings File</h6>
              <div className="upload-controls">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".xlsx"
                  id="fileInput"
                />
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="download-snapshot-container mt-5">
              <h6>Get latest inventory Snapshot</h6>
              {downloadButtonClicked && (
                <Alert
                  variant="success"
                  style={{
                    maxWidth: "800px",
                    fontSize: "14px",
                  }}
                >
                  Your request has been successfully submitted, you will get the
                  file from file history table once it's ready.
                </Alert>
              )}
              <Button
                size="sm"
                variant="outline-primary"
                onClick={handleDownload}
              >
                Download
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Download Section */}

      {/* Upload History Table */}
      <div className="upload-history-container mt-5 mb-5 pb-5">
        <div className="files-history-header">
          <h6 className="mr-4">Files History</h6>

          <a
            href="#"
            onClick={() => {
              getFilesHistory();
              toast.success("File History refreshed!", {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
              });
            }}
          >
            Refresh
          </a>
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>File Name</th>
              <th>File Operation</th>
              <th>Status</th>
              <th>Message</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            {filesHistory &&
              filesHistory.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.id}</td>
                  <td>{DateTimeUtils.formatDateTime(entry.created_at)}</td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        downloadLoadedFile(entry.file_path);
                      }}
                    >
                      {entry.file_path}
                    </a>
                  </td>
                  <td>{entry.operation}</td>
                  <td>{entry.status}</td>
                  <td>{entry.status_message}</td>
                  <td>{entry.created_by}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListingsBulkOperations;
