import React, { useEffect, useState } from "react";
import "./CSS/FormsHome.css";
import FormsNetworkUtil from "./NetworkingUtils/FormsNetworkUtils";
import { Link, useHistory } from "react-router-dom";
import { ActiveTab__Action } from "../../state/action-creators";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import { useAppSelector } from "../../state/hooks";

function FormsHome() {
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const formsNetworkUtil = new FormsNetworkUtil(
    "https://your-api-endpoint.com",
    history
  );
  const [categories, setFormCategoris] = useState([]);
  const dispatch: AppDispatch = useDispatch();
  const transformCategory = (category_name: string) => {
    return category_name.split("_").join(" ");
  };

  useEffect(() => {
    if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
      history.push(`/listings/all`);
    }
    dispatch(ActiveTab__Action("forms"));
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await formsNetworkUtil.getAllFormCategories();
        const formCategories = response.data;
        console.log(formCategories);
        setFormCategoris(formCategories);
        // Do something with formCategories
      } catch (error) {
        console.log("Printing error from ListingsHome");
        console.error("Error:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleClick = (category: string) => {
    console.log(category, "clicked");
    history.push(`/forms/${category}`);
  };

  return (
    <div className="forms-home-container">
      <div className="forms-card-container">
        {categories.map((category) => (
          <div
            className="forms-card"
            key={category}
            onClick={() => handleClick(category)}
          >
            {transformCategory(category)}
            {/* <Link to={`/forms/${category}`}>{category}</Link> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FormsHome;
function dispatch(
  arg0: (
    dispatch: import("redux").Dispatch<
      import("../../state/actions-interface").ActiveTabAction
    >
  ) => void
) {
  throw new Error("Function not implemented.");
}
