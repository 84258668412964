import React, { useState, useEffect } from "react";
import "./CSS/ListingsView.css";
import { Listing, ListingPage } from "../../atomic_components/types";
import ListingDetails from "./ListingDetails";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
}

const ListingImage: React.FC<ListingImageProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // console.log(currentImageIndex);

  // const handleImageError = () => {
  //   // Move to the next image if the current one fails to load
  //   setCurrentImageIndex((prevIndex) => prevIndex + 1);
  // };

  useEffect(() => {
    console.log(currentImageIndex);
  }, [currentImageIndex]);

  useEffect(() => {
    // Find the index of the first non-null and non-empty image

    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].trim().length > 10) {
        setCurrentImageIndex(i);
        return;
      }
    }
    // If no valid image is found, set index to null
    setCurrentImageIndex(-1);
  }, [images]);

  const handleImageError = () => {
    // setCurrentImageIndex((prevIndex) => {
    //   let nextIndex = prevIndex + 1;
    //   while (
    //     nextIndex < images.length &&
    //     images[nextIndex].trim().length < 10
    //   ) {
    //     console.log(images[nextIndex].trim().length);
    //     nextIndex++;
    //   }
    //   if (nextIndex == images.length) return -1;
    //   return nextIndex;
    // });

    // setCurrentImageIndex((prevIndex) => {
    console.log("error thrown for index , ", currentImageIndex);
    let nextIndex = currentImageIndex + 1;

    // Iterate over the images array to find the next valid image index
    while (nextIndex < images.length) {
      // Check if the current image URL is valid
      if (images[nextIndex] && images[nextIndex].trim().length >= 10) {
        console.log("Valid image found at index:", nextIndex);
        setCurrentImageIndex(nextIndex);
        return; // Return the index of the valid image
      }
      console.log("Invalid image at index:", nextIndex);
      nextIndex++; // Move to the next image
    }

    // If no valid image is found, set index to a fallback image
    console.log("No valid image found, setting index to fallback");
    setCurrentImageIndex(-1); // Set index to indicate fallback image
    // });
  };

  return (
    <div className="listings-img-container">
      {currentImageIndex == -1 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
        />
      ) : (
        <img
          src={images[currentImageIndex]}
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  listing: Listing;
  category: string;
  handleCardClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    listing: Listing
  ) => void;
}> = ({ listing, category, handleCardClick }) => {
  const [localListing, setLocalListing] = useState<Listing>(listing);
  const handleImageError = (imageKey: string) => {
    const updatedListing = { ...localListing };
    updatedListing[imageKey] = "http://tech.intellozene.com/Media/na.png";
    setLocalListing(updatedListing);
  };

  return (
    <a href={`/listings/${category}/${listing.sku_id}`}>
      <div
        className="listings-view-card"
        onClick={(e) => handleCardClick(e, localListing)}
      >
        {/* <div className="listings-img-container">
          <img
            src={localListing.img_wdim}
            alt="Product Image"
            onError={() => handleImageError("img_wdim")}
          />
        </div> */}

        <ListingImage
          images={[
            listing.img_wdim,
            listing.img_hd,
            listing.img_white,
            listing.img_link1,
            listing.img_link2,
          ]}
        />

        <div className="listings-view-card-title">
          <h3
            className={`sku-id-title ${
              localListing.sku_type && localListing.sku_type == "MULTI"
                ? "multi"
                : localListing.available_quantity === 0
                ? "not-available"
                : "available"
            }`}
          >
            {localListing.sku_id}
          </h3>
        </div>
        <p>OPS Tag: {localListing.ops_tag}</p>
        <p>Inv SKU: {localListing.inventory_sku_id}</p>
        {localListing.sku_type && localListing.sku_type == "MULTI" ? (
          <p className="listings-view-card-bold-text">MULTI</p>
        ) : (
          <p
            className={`listings-view-card-bold-text ${
              localListing.available_quantity === 0 ? "red" : "green"
            }`}
          >
            Available quantity : {localListing.available_quantity}
          </p>
        )}
      </div>
    </a>
  );
};

type ListingsViewProps = {
  listings: Listing[];
};

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

// export default function ListingsView(data: ListingPage) {
export default function ListingsView({ listings }: ListingsViewProps) {
  // console.log(listings);
  const history = useHistory();
  const { category } = useParams<RouteParams>();

  // useEffect(() => {
  //   // Add event listener for the 'popstate' event
  //   window.addEventListener("popstate", (event) => {
  //     // Check if the state includes the property skuDetailsOpen
  //     // console.log("inside  popstate event");
  //     // console.log(event.state);
  //     // if (event.state && event.state.skuDetailsOpen) {
  //     handleClose(); // Close the overlay
  //     // }
  //   });

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("popstate", handleClose);
  //   };
  // }, []);

  const handleCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    listing: Listing
  ) => {
    // Use pushState to add a new history state without navigating
    // window.history.pushState({ skuDetailsOpen: true }, ""); // The second parameter is the title (can be empty)
    // history.push(`/listings/${category}/${listing.sku_id}`);
    // const url = `/listings/${category}/${listing.sku_id}`;
    // const isNewTab = event.metaKey || event.ctrlKey;
    // if (isNewTab) {
    //   const win = window.open(url, "_blank");
    //   // const win = window.open(url);
    //   if (win) {
    //     win.focus(); // Focus on the new tab if it opened successfully
    //   } else {
    //     console.error("Popup blocked. Please allow popups for this website.");
    //   }
    // } else {
    //   history.push(url);
    // }
  };

  return (
    <div className="all-listings-only-card-container">
      {listings &&
        listings.length > 0 &&
        listings.map((listing, index) => (
          <ListingCard
            key={index}
            listing={listing}
            category={category}
            handleCardClick={handleCardClick}
          />
        ))}
    </div>
  );
}
